import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Input, notification, PageHeader, Popconfirm, Space, Table, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import React, { FC, Fragment, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { IsUnique } from "../../components/is-unique";
import { Pagination } from "../../components/pagination";
import { IProfile } from "../../interfaces/profile";
import { IUser } from "../../interfaces/user";
import { request } from "../../utils/request";

export const Profiles: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [query, setQuery] = useState("");
  const page = searchParams.has("page") ? Number(searchParams.get("page")) : 1;
  const [banDay, setBanDay] = useState("");

  const [profiles, setProfiles] = useState({
    data: [],
    pagination: {
      count: 0,
      limit: 0,
      offset: 0,
    },
  });
  const [loading, setLoading] = useState(true);

  const search = (query: string) => {
    setQuery(query);
    setSearchParams({ page: "1" });
  };

  const setOnline = async (userId: number, online: boolean) => {
    try {
      await request.post("/profile/administrator/online", {
        userId,
        online,
      });

      notification.success({
        message: "Онлайн установлен",
        description: "Ура товарищи!",
      });

      await getProfiles();
    } catch (err) {
      console.error(err);
    }
  };

  const emailConfirmedToggle = async (userId: number, confirmed: boolean) => {
    setLoading(true);

    await request.post("/users/email-change-confirmed", {
      userId,
      confirmed,
    });

    await getProfiles();
  };

  const changeMailingCount = async (profileId: number) => {
    try {
      await request.post("/chat-mailing/change-mailing-count", { profileId });

      notification.success({
        message: "Рассылка добавлена",
        description: "Ура товарищи!",
      });
    } catch (err) {
      console.error(err);
    }
  };

  const getProfiles = async () => {
    const response = await request.post("/profile/administrator/list", {
      offset: 50 * (page - 1),
      query,
    });

    setProfiles(response.data);
    setLoading(false);
  };

  const remove = async (id: number) => {
    setLoading(true);

    await request.post("/users/delete", {
      id,
    });

    await getProfiles();
  };

  const subscribeToggle = async (email: string, subscribed: boolean) => {
    setLoading(true);

    await request.post("/mail/subscribe-administrator", {
      email,
      subscribed: !subscribed,
    });

    await getProfiles();
  };

  const ban = async (user: IUser) => {
    setLoading(true);

    const banned = !user.isBanned;

    await request.post("/users/ban", {
      id: user.id,
      banned,
      days: banned && banDay ? Number(banDay) : undefined,
    });

    setBanDay("");
    await getProfiles();
  };

  useEffect(() => {
    getProfiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, page]);

  const columns: ColumnsType<IProfile> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (id, profile) => (
        <Space direction="vertical" size={0}>
          <span>{id}</span>
          <span style={{ fontSize: 12, color: "#555" }}>{moment(profile.createdAt).format("DD.MM.YYYY")}</span>
        </Space>
      ),
    },
    {
      title: "Статус",
      render: (_, profile) =>
        profile.user.isBanned ? <Tag color="red">Забанен</Tag> : <Tag color="green">Активен</Tag>,
    },
    {
      title: "Уникален",
      dataIndex: "id",
      render: (id) => <IsUnique profileId={id} />,
    },
    {
      title: "Почта/Тел.",
      key: "id",
      render: (_, profile) => (
        <Space direction="vertical">
          <span>{profile.user.email}</span>
          {!!profile.user.phone && <span>{profile.user.phone}</span>}
        </Space>
      ),
    },
    {
      title: "E-mail подтвержден",
      dataIndex: "user",
      key: "email_confirmed",
      render: ({ id, email_confirmed }) => (
        <Button
          onClick={() => emailConfirmedToggle(id, !email_confirmed)}
          size="small"
          type="primary"
          danger={!email_confirmed}
        >
          {email_confirmed ? "Да" : "Нет"}
        </Button>
      ),
    },
    {
      title: "Подписка",
      dataIndex: "user",
      key: "subscribe",
      render: ({ email, subscribe }) => (
        <Button
          loading={loading}
          onClick={() => subscribeToggle(email, !!subscribe?.subscribed)}
          size="small"
          type="primary"
          danger={!subscribe?.subscribed}
        >
          {subscribe?.subscribed ? "Да" : "Нет"}
        </Button>
      ),
    },
    {
      title: "Имя",
      dataIndex: "name",
      key: "id",
    },
    {
      title: "Премиум",
      dataIndex: "premium",
      key: "id",
      render: (_, profile) => <Fragment>{profile.premium ? <Tag color="green">Премиум</Tag> : "Нет"}</Fragment>,
    },
    {
      title: "Аккаунт подтвержден",
      dataIndex: "confirmed",
      key: "id",
      render: (_, profile) => <Fragment>{profile.confirmed ? <Tag color="green">Подтвержден</Tag> : "Нет"}</Fragment>,
    },
    {
      title: "Фото подтверждено",
      dataIndex: "photo_confirmed",
      key: "id",
      render: (_, profile) => (
        <Fragment>{profile.photo_confirmed ? <Tag color="green">Подтверждено</Tag> : "Нет"}</Fragment>
      ),
    },
    {
      title: "Топ",
      dataIndex: "top_end_date",
      key: "id",
      render: (_, profile) => (
        <Fragment>
          {moment(profile.top_end_date).isAfter() ? (
            <Tag color="green">{moment(profile.top_end_date).format("DD.MM.YYYY")}</Tag>
          ) : (
            "Нет"
          )}
        </Fragment>
      ),
    },
    {
      title: "Топ 100",
      dataIndex: "top_100_end_date",
      key: "id",
      render: (_, profile) => (
        <Fragment>
          {moment(profile.top_100_end_date).isAfter() ? (
            <Tag color="green">{moment(profile.top_100_end_date).format("DD.MM.YYYY")}</Tag>
          ) : (
            "Нет"
          )}
        </Fragment>
      ),
    },
    {
      title: "Онлайн",
      key: "action",
      render: (_, profile) => (
        <Space>
          {profile.online ? (
            <Button danger type="dashed" onClick={() => setOnline(profile.user.id, false)} size="small">
              Убрать
            </Button>
          ) : (
            <Button type="dashed" onClick={() => setOnline(profile.user.id, true)} size="small">
              Добавить
            </Button>
          )}
        </Space>
      ),
    },
    {
      title: "Дата онлайна",
      key: "online_date",
      render: (_, profile) => (profile.online_date ? moment(profile.online_date).format("DD.MM.YYYY HH:mm:ss") : "Нет"),
    },
    {
      title: "Чаты",
      key: "id",
      render: (_, profile) => (
        <Button href={`/chats/${profile.id}`} target="_blank" type="dashed" size="small">
          Чаты
        </Button>
      ),
    },
    {
      title: "Бан",
      dataIndex: "user",
      render: (user) => {
        return (
          <Fragment>
            {user.isBanned ? (
              <Space direction="vertical">
                {user.banned_end_date ? <span>{moment(user.banned_end_date).format("DD.MM.YYYY HH:mm")}</span> : null}
                <Button onClick={() => ban(user)} size="small" danger={!user.isBanned}>
                  Активировать
                </Button>
              </Space>
            ) : (
              <Space direction="vertical">
                <Input
                  value={banDay}
                  onChange={({ target: { value } }) => setBanDay(value)}
                  size="small"
                  placeholder="Дней"
                  style={{ width: 105 }}
                />
                <Popconfirm
                  placement="leftTop"
                  title="Уверен?"
                  onConfirm={() => ban(user)}
                  okText="Да"
                  cancelText="Нет"
                >
                  <Button size="small" danger={!user.isBanned}>
                    Заблокировать
                  </Button>
                </Popconfirm>
              </Space>
            )}
          </Fragment>
        );
      },
    },
    {
      title: "Управление",
      key: "action",
      render: (_, profile) => (
        <Space>
          <Button type="dashed" onClick={() => changeMailingCount(profile.id)} size="small">
            +1 рассылка
          </Button>
          <Button icon={<EditOutlined />} href={`/profile/${profile.id}`} target="_blank" type="primary" size="small" />
          <Popconfirm
            placement="leftTop"
            title="Удалить пользователя?"
            onConfirm={() => remove(profile.user.id)}
            okText="Да"
            cancelText="Нет"
          >
            <Button size="small" type="primary" danger={true} icon={<DeleteOutlined />} />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <PageHeader ghost={false} title="Профиля" />
      <Input.Search size="large" style={{ width: "100%", marginBottom: 16 }} placeholder="Поиск" onSearch={search} />
      <Table
        loading={loading}
        rowKey="id"
        pagination={false}
        scroll={{ x: 300 }}
        columns={columns}
        dataSource={profiles.data}
      />
      <Pagination
        count={profiles.pagination.count}
        limit={profiles.pagination.limit}
        page={searchParams.has("page") ? Number(searchParams.get("page")) : 1}
      />
    </div>
  );
};
